import {
  BLOCKS_VIEW_HEADER_INNER_HEIGHT,
  BLOCKS_VIEW_NODE_PAD,
  BLOCKS_VIEW_NODE_SEP,
} from '../consts';
import { BlocksViewLink, BlocksViewNode, RectangleSides } from '../types';
import { isGroup } from '../viewModel$/util';

export const isSyntheticGroup = (node: BlocksViewNode) => {
  return isGroup(node) && (!node.parent || node.id === 'rootLeafGroup');
};

export const isNodeHitVisible = (node: BlocksViewNode) => {
  return !isSyntheticGroup(node);
};

export const isLinkHitVisible = (link: BlocksViewLink) => {
  return link.globalTypeId !== null;
};

export const collapsedGroupHeaderBottom = (cell: RectangleSides) => {
  if (!cell) {
    return NaN;
  }
  const [, top] = cell;
  return (
    top +
    BLOCKS_VIEW_NODE_PAD +
    BLOCKS_VIEW_HEADER_INNER_HEIGHT +
    BLOCKS_VIEW_NODE_SEP
  );
};
