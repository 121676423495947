import type { Point } from '@ardoq/math';
import { NODE_MARGIN } from '../consts';
import { RelationshipsNode } from '../types';

const MIN_EXPANDER_SCALE = 10 / NODE_MARGIN;

export const shouldShowExpander = (
  { children }: RelationshipsNode,
  scale: number
) => Boolean(children?.length && scale > MIN_EXPANDER_SCALE);

export const markFatness = (radius: number) =>
  Math.min(4 * devicePixelRatio, radius / 4);

export const badgeLocation = (x: number, y: number, r: number): Point => [
  x + r * Math.cos(Math.PI * 1.75),
  y + r * Math.sin(Math.PI * 1.75),
];
