import {
  enhancedScopeDataOperations,
  fieldUtils,
  workspaceUtils,
} from '@ardoq/scope-data';
import { componentInterface } from '@ardoq/component-interface';
import { workspaceInterface } from '@ardoq/workspace-interface';
import { fieldInterface } from '@ardoq/field-interface';
import { GraphContext } from '@ardoq/graph';
import { groupingRuleInterface } from 'modelInterface/groupingRules/groupingRuleInterface';
import { ArdoqId } from '@ardoq/api-types';

/**
 * This is a temporary solution to make the transition from BB models to scope data easier.
 *
 * The next step is to create an object with functions that have the same signature as the model interface functions,
 * but are bound to the current scope data. This way we can use the scopeData or BB models interchangeably with minimal
 * refactoring. As soon BB models are no more, we shall pass the scope data instead of the interface object and use the
 * utility functions directly.
 *
 */

export type TimelineSpecificInterface = ReturnType<
  typeof getTimelineSpecificInterfaceWithModelInterfaces
>;

export const getTimelineSpecificInterfaceWithModelInterfaces = () => ({
  getComponentWorkspaceModelId: componentInterface.getModelId,
  getComponentFieldValue: componentInterface.getFieldValue,
  getAllComponentIds: componentInterface.getAllComponentIds,

  getComponentTypesForWorkspace: workspaceInterface.getComponentTypes,
  getWorkspaceNameById: workspaceInterface.getWorkspaceName,

  getFieldsOfWorkspace: fieldInterface.getFieldsOfWorkspace,

  getAllGroupingRules: groupingRuleInterface.getAll,
});

export const getTimelineSpecificInterfaceForEnhancedScopeData = ({
  enhancedScopeData,
  groupingRules,
}: Pick<
  GraphContext,
  'enhancedScopeData' | 'groupingRules'
>): TimelineSpecificInterface => ({
  getComponentWorkspaceModelId: (componentId: ArdoqId) => {
    const component = enhancedScopeDataOperations.getComponent(
      enhancedScopeData,
      componentId
    );
    return component?.modelId ?? null;
  },
  getComponentFieldValue: (componentId, fieldName) => {
    const component = enhancedScopeDataOperations.getComponent(
      enhancedScopeData,
      componentId
    );
    return component ? component[fieldName] : null;
  },
  getAllComponentIds: () => enhancedScopeData.components.map(({ _id }) => _id),
  getComponentTypesForWorkspace: workspaceId =>
    workspaceUtils.getAPIComponentTypes(enhancedScopeData, workspaceId),
  getWorkspaceNameById: workspaceId =>
    enhancedScopeData.workspacesById[workspaceId]?.name ?? null,

  getFieldsOfWorkspace: (workspaceId, fieldTypes) =>
    fieldUtils
      .getAllFieldsInWorkspace(enhancedScopeData, workspaceId)
      .filter(field => fieldTypes.includes(field.type)),

  getAllGroupingRules: () => groupingRules, // this is stupid, but it's about the shared pattern?
});
