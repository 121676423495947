import {
  BLOCKS_VIEW_GROUP_BADGE_FONT,
  BLOCKS_VIEW_NODE_PAD,
  INVALID_RECTANGLE_SIDES,
} from '../consts';
import { type BlocksViewNode, type RectangleSides } from '../types';
import canvasLabelMeasurer from './canvasLabelMeasurer';

const DESCENDANT_COUNT_BADGE_PADDING = 6;
const DESCENDANT_COUNT_BADGE_HEIGHT = 20;

const measureBadgeLabel = canvasLabelMeasurer(BLOCKS_VIEW_GROUP_BADGE_FONT);

const descendantCountBadgeRect = ({
  children,
  cell,
}: BlocksViewNode): RectangleSides => {
  if (!children?.length || !cell) {
    return INVALID_RECTANGLE_SIDES;
  }
  const badgeLabelWidth = measureBadgeLabel(`${children.length}`);
  const badgeWidth = badgeLabelWidth + 2 * DESCENDANT_COUNT_BADGE_PADDING;
  const [, top, right] = cell;
  return [
    right - BLOCKS_VIEW_NODE_PAD - badgeWidth,
    top + BLOCKS_VIEW_NODE_PAD,
    right - BLOCKS_VIEW_NODE_PAD,
    top + BLOCKS_VIEW_NODE_PAD + DESCENDANT_COUNT_BADGE_HEIGHT,
  ];
};

export default descendantCountBadgeRect;
