import { colors } from '@ardoq/design-tokens';
import { svgImage } from 'tabview/canvasRendering/canvasResolvedImages';
import descendantCountBadgeTextAndIconMetrics from './descendantCountBadgeTextAndIconMetrics';
import descendantCountBadgeFont from './descendantCountBadgeFont';
import type { CanvasRenderingContext } from '../types';

const DESCENDANT_COUNT_BADGE_TEXT_BASELINE = 'alphabetic';

// args interpolation: ${iconId}~~${color}~~${fill}~~${stroke}~~${width}~~${height}
const DISCONNECTED_COMPONENTS_IMAGE = svgImage(
  `svg_sprite_bullseye~~${colors.red80}`
);

type DrawDescendantCountBadgeArgs = {
  fontSize: number;
  x: number;
  y: number;
  descendantCount: number;
  disconnectedChildrenCount: number;
  context: CanvasRenderingContext;
  disconnectedNodesHighlighted: boolean;
};
const drawDescendantCountBadge = ({
  fontSize,
  x,
  y,
  descendantCount,
  disconnectedChildrenCount,
  context,
  disconnectedNodesHighlighted,
}: DrawDescendantCountBadgeArgs) => {
  context.beginPath();
  context.fillStyle = colors.grey35;
  context.font = descendantCountBadgeFont(fontSize);
  context.textBaseline = DESCENDANT_COUNT_BADGE_TEXT_BASELINE;

  const {
    badgeMetrics: {
      badgeX,
      badgeY,
      badgeHeight,
      badgeWidth,
      badgeLeft,
      badgeRight,
      badgeBottom,
      badgeTop,
      childCountBadgePadding,
    },
    textMetrics: { badgeLabeLBeforeIconWidth },
    iconMetrics: { iconX, iconY, iconR },
    quantityOfDisconnectedChildren,
    isDisconnectedChildrenCountShown,
  } = descendantCountBadgeTextAndIconMetrics(
    disconnectedChildrenCount,
    fontSize,
    x,
    y,
    descendantCount,
    context,
    disconnectedNodesHighlighted
  );

  const iconDiameter = iconR * 2;

  const badgeShortening = Math.min(badgeWidth / 2, 8);
  context.moveTo(badgeLeft, badgeTop);
  context.lineTo(badgeRight, badgeTop);
  context.arc(
    badgeRight - badgeShortening,
    badgeY,
    badgeHeight / 2,
    Math.PI + Math.PI / 2,
    Math.PI / 2
  ); // bottom right

  context.lineTo(badgeLeft, badgeBottom);
  context.arc(
    badgeLeft + badgeShortening,
    badgeY,
    badgeHeight / 2,
    Math.PI / 2,
    Math.PI + Math.PI / 2
  ); // top left

  // label text: numbers and parentheses are not centrally aligned in the font, we have to align them manually
  context.fill();
  context.fillStyle = colors.white;
  context.textAlign = 'start';

  // label before icon
  // 1.1 factor is magic, needed for center-alignement. Does not make sense mathematically,
  // but works. It seems that the font is misplacing the numbers in the box.
  const CENTRAL_ALIGNMENT_CORRECTION_FOR_NUMBERS = 1.1;

  context.fillText(
    `${descendantCount}${
      isDisconnectedChildrenCountShown
        ? ` (${quantityOfDisconnectedChildren}`
        : ''
    }`,
    badgeLeft + childCountBadgePadding,
    badgeBottom -
      childCountBadgePadding * CENTRAL_ALIGNMENT_CORRECTION_FOR_NUMBERS
  );

  if (isDisconnectedChildrenCountShown) {
    // label after icon
    context.fillText(
      ')',
      badgeX -
        badgeWidth / 2 +
        childCountBadgePadding +
        badgeLabeLBeforeIconWidth +
        iconDiameter,
      badgeBottom -
        childCountBadgePadding * CENTRAL_ALIGNMENT_CORRECTION_FOR_NUMBERS
    );

    context.drawImage(
      DISCONNECTED_COMPONENTS_IMAGE,
      iconX,
      iconY,
      iconDiameter,
      iconDiameter
    );
  }
};

export default drawDescendantCountBadge;
