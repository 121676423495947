import {
  BLOCKS_VIEW_NODE_PAD,
  BLOCKS_VIEW_URL_ICON_SIZE,
  INVALID_RECTANGLE_SIDES,
} from '../consts';
import { type BlocksViewNode, type RectangleSides } from '../types';
import { collapsedGroupHeaderBottom } from './util';

const leafUrlIconRect = ([, top, right]: RectangleSides): RectangleSides => [
  right - BLOCKS_VIEW_NODE_PAD - BLOCKS_VIEW_URL_ICON_SIZE,
  top + BLOCKS_VIEW_NODE_PAD,
  right - BLOCKS_VIEW_NODE_PAD,
  top + BLOCKS_VIEW_NODE_PAD + BLOCKS_VIEW_URL_ICON_SIZE,
];
const collapsedComponentGroupUrlIconRect = (
  cell: RectangleSides
): RectangleSides => {
  const [, , right] = cell;
  const headerBottom = collapsedGroupHeaderBottom(cell);
  return [
    right - BLOCKS_VIEW_NODE_PAD - BLOCKS_VIEW_URL_ICON_SIZE,
    headerBottom + BLOCKS_VIEW_NODE_PAD,
    right - BLOCKS_VIEW_NODE_PAD,
    headerBottom + BLOCKS_VIEW_NODE_PAD + BLOCKS_VIEW_URL_ICON_SIZE,
  ];
};
const urlIconRect = ({
  children,
  open,
  cell,
}: BlocksViewNode): RectangleSides =>
  !cell
    ? INVALID_RECTANGLE_SIDES
    : children?.length
      ? open
        ? INVALID_RECTANGLE_SIDES
        : collapsedComponentGroupUrlIconRect(cell)
      : leafUrlIconRect(cell);

export default urlIconRect;
